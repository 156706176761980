import React, { useState, useEffect } from 'react';


const CountdownTimer = (props) => {
  const [seconds, setSeconds] = useState(30); 
  const [resetCallback, setResetCallback] = useState(false);

  
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds(prevSeconds => {
        if (prevSeconds === 29) {

          fetch('https://crypto-casino-backend.sead-ctf.student.iaik.tugraz.at/hash-winning-value', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: localStorage.getItem("username") 
            })
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }).then(data => {
            const value = data.hash_win_value
            props.countdownSetHashValue(value)
            
          }).catch(error => {
              console.error('There was a problem with the fetch operation:', error);
          })
          return 28; 
        } else {
          if(prevSeconds > 0)
            return prevSeconds - 1;
          else{
            setResetCallback(prevValue => !prevValue)
            return 30;
          }
        }
      });
    }, 1000); 

    return () => clearInterval(intervalId);
  }, []); 

  useEffect(()=>{
    props.resetPlaceBet();
  }, [resetCallback])
  

  return (
    <div>
      <p>Time remaining: {seconds} seconds</p>
    </div>
  );
};

export default CountdownTimer;