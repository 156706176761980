import Alert from 'react-bootstrap/Alert';

const  UnderageAlert = () => {
  return ( 
    <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.5)", zIndex: 999 }}>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <div>
        <Alert variant="danger" dismissible>
            <Alert.Heading>Oh snap! You can not play!</Alert.Heading>
            <p>
                This is the fastest way out of the 9-5 rat race but you need to be careful. 
                Here, we respect the law and are not able to accomodate minors.
                You are more than welcome to join us and try out your luck once you turn 18.
            </p>
        </Alert>
        </div>
    </div>
    </div>    
  );
}

export default UnderageAlert;