import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import UserForm from './UserForm';
import RoulettePage from './RoulettePage';
import Welcome from './Welcome';
import { useState, useEffect } from 'react';
import Rules from './Rules';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');


  const setLoggedInHandler = (n) => {
    localStorage.setItem('username', n);
    localStorage.setItem('isLoggedIn', true);
    setUsername(n);
    setIsLoggedIn(true);
  }

  const setLogOutHandler = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=None";
    setIsLoggedIn(false);
    localStorage.clear();
  }

  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
   
    if (storedUsername && storedIsLoggedIn) {
      setUsername(storedUsername);
      setIsLoggedIn(true);
    }
  }, [])

  return (
    <Router>
      <div className="App d-flex flex-column" style={{ height: '100vh' }}>
        <Header isLoggedIn={isLoggedIn} setLogOutHandler={setLogOutHandler} username={username}/>
        <Routes>
          <Route path="/signup" element={<UserForm formType='signup' setLoggedInHandler={setLoggedInHandler}/>} />
          <Route path="/login" element={<UserForm formType='login' setLoggedInHandler={setLoggedInHandler}/>} />
          <Route path="/roulette" element={<RoulettePage />} />
          <Route path="/" element={<Welcome />} /> 
          <Route path="/rules" element={<Rules />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
