import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
import AlertDismissibleExample from './WrongCredentialsAlert';

const UserForm = ({formType, setLoggedInHandler}) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [showAlert, setShowAlert] = useState(false);

    const handleShowAlert = () => {
        setShowAlert(false);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://crypto-casino-backend.sead-ctf.student.iaik.tugraz.at/login', {
                email: formData.email,
                password: formData.password,
                formType: formType 
            });
    
            if (response.status === 200) {
                const token = response.data.token;
                localStorage.setItem('jwt-token', token);
                document.cookie = `token=${token}; SameSite=None;`;
                setLoggedInHandler(formData.email);
                navigate('/rules');
            }
        } catch (error) {
            setShowAlert(true);
            console.error('Error when logging in/ signin up:', error);
        }
    };

    if(showAlert){
        return <AlertDismissibleExample formType={formType} handleShowAlert={handleShowAlert}/>;
    }
    
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
            <div className="w-30">
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control 
                            type="email" 
                            name="email"
                            placeholder="Enter email" 
                            value={formData.email}
                            onChange={handleChange}
                            style={{ backgroundColor: 'black', color: 'white' }} 
                        />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="password"
                            placeholder="Password" 
                            value={formData.password}
                            onChange={handleChange}
                            style={{ backgroundColor: 'black', color: 'white' }} 
                        />
                    </Form.Group>
                    <Button variant="dark" type="submit" id='submit-btn' style={{ backgroundColor: 'black', color: 'red' }}>
                        {formType === 'login' ? 'Log in' : 'Sign up'}
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default (UserForm);