import Alert from 'react-bootstrap/Alert';

const  FlagAlert = () => {
  return ( 
    <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(255, 255, 255, 0.5)", zIndex: 999 }}>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
        <div>
        <Alert variant="success">
            <Alert.Heading id="alert-heading">{"MONEY{r=00_cKs8}"}</Alert.Heading>
            <p>
               Congrats on solving the challenge!
            </p>
        </Alert>
        </div>
    </div>
    </div>
  );
}

export default FlagAlert;