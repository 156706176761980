import Alert from 'react-bootstrap/Alert';

function AlertDismissibleExample({formType, handleShowAlert}) {

    return (
      <Alert variant="danger" onClose={handleShowAlert} dismissible>
        <Alert.Heading>Oh snap! You made a mistake!</Alert.Heading>
        
        {formType === 'login' && <p>Invalid email or password!</p>}
        {formType === 'signup' && <p>Email and password are required and have to be unique!</p>}
        
      </Alert>
    );
  
}

export default AlertDismissibleExample;