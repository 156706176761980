const Welcome = () => {
    return(
        <div>
            <h1>Welcome to our casino where you can only play one simplified game
                and never win hehe
            </h1>
        </div>
    )
}

export default Welcome;