const Rules = () => {
    return(
        <>
            <h1>THE RULES</h1>
            <p>
                The minimum amount of money to play with is 20 coins, and you will always have them, but if you aim
                to earn more money, you will have to work smart and place winning bets more often.
                Each round of the game is 30 seconds, and you have to place a bet within that time frame.
                Your goal is to achieve a stellar streak and unlock the reward you are here for.
                Each time you leave the table (tab), you lose your progress, so thread carefully.
                Take caution, as leaving the page will cause the cookie to expire promptly.
            </p>
        </>
    )
}

export default Rules;