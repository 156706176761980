// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container button {
    margin-right: 20px; 
    padding: 5px 15px;
}`, "",{"version":3,"sources":["webpack://./src/Header.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".button-container button {\n    margin-right: 20px; \n    padding: 5px 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
