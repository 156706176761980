import './Header.css';
import { useNavigate, Link } from 'react-router-dom';

const Header = ({ isLoggedIn, setLogOutHandler, username }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    setLogOutHandler()
  }

  const renderContent = () => {
    if (isLoggedIn) {
      return (
        <div>
          <span>Hello, {username} </span>
          <button className="btn btn-danger btn-lg" onClick={handleLogout}>logout</button>
        </div>
      );
    } 
    else {
      return (
        <div className="button-container">
          <button className="btn btn-danger btn-lg" onClick={() => navigate('/login')}>LOGIN</button>
          <button className="btn btn-danger btn-lg" onClick={() => navigate('/signup')}>SIGNUP</button>
        </div>
      );
    }
  };

  return (
    <header className="bg-dark text-danger p-2">
      <div className="container d-flex justify-content-between align-items-center">
        <h1>CASINO ROULETTE</h1>

        {isLoggedIn && <nav>
          <ul className="navbar-nav d-flex flex-row">
            <li className="nav-item">
              <Link 
                className="nav-link px-4" 
                to="/rules" 
                style={{ fontWeight: 'bold' }}
              >
                RULES
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className="nav-link px-4" 
                id="roulette"
                to="/roulette" 
                style={{ fontWeight: 'bold' }}
              >
                ROULETTE
              </Link>
            </li>
          </ul>
        </nav>}

        {renderContent()}
      </div>
    </header>

  );
};

export default Header;