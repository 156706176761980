import 'bootstrap/dist/css/bootstrap.min.css';
import rouletteBoard from "./rouletteBoard.jpg";
import { useState, useEffect } from 'react';
import UnderageAlert from './UnderageAlert';
import FlagAlert from './FlagAlert';
import CountdownTimer from './CountdownTimer';

const RoulettePage = () => {
    const [amountOfCoinsToBet, setAmountOfCoinsToBet] = useState(20);
    const [sumOfCoins, setSumOfCoins] = useState(20);
    const [hashBet, setHashBet] = useState(0);
    const [placeBetOnce, setPlaceBetOnce] = useState();
    const [userBet, setUserBet] = useState('');
    const [userStreak, setUserStreak] = useState(0);
    const [isUnderage, setIsUnderage] = useState();

    const checkUserBet = (e) => {
      const value = parseInt(e.target.value, 10);
      if (!isNaN(value) && value >= 0 && value <= 36) { 
        setUserBet(value.toString()); 
      } else {
        setUserBet('');
      }
    }

    const resetPlaceBet = () => {
      setPlaceBetOnce(0);
    }

    const checkPlayerAge = () => {
      fetch('https://crypto-casino-backend.sead-ctf.student.iaik.tugraz.at/age-check', {
        method: 'GET',
        credentials: 'include'
      }).then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json();
      }).then(data => {
          setIsUnderage(data.underage === 'true')
      }).catch(error => {
        setIsUnderage(true);
        console.error('There was a problem with the fetch operation:', error)
      });
    }

    const countdownSetHashValue = (value) => {
      setHashBet(value)
    }

    const handlePlaceBetClick = () => {
      var bet = parseInt(userBet, 10)

      fetch('https://crypto-casino-backend.sead-ctf.student.iaik.tugraz.at/check-user-placed-bet', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: localStorage.getItem("username"),
              userBet: bet,
              user_hash: hashBet
            })
          }).then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          }).then(data => {

            if(data.win == 'true'){
              setUserStreak(prevValue => prevValue+=1) 
              setSumOfCoins(prevValue => prevValue+(amountOfCoinsToBet))
            }
            else{
              setSumOfCoins(prevValue => prevValue-(amountOfCoinsToBet) <= 0 ? 20 : prevValue-(amountOfCoinsToBet))
              setAmountOfCoinsToBet(20);  
              setUserStreak(0);
            }
            setPlaceBetOnce(1);
            
          }).catch(error => {
              console.error('There was a problem with the fetch operation:', error);
          })

    }

  useEffect(() => {
    checkPlayerAge();
  }, [isUnderage]);

    if(isUnderage){
      return <UnderageAlert />
    }

    if(userStreak >= 13){
      return <FlagAlert/>
    }

    return(
      <>
      <div className="container-fluid position-relative d-flex justify-content-center align-items-center vh-100">
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src={rouletteBoard} alt="Roulette board" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
        <CountdownTimer countdownSetHashValue={countdownSetHashValue} resetPlaceBet={resetPlaceBet}/>
        <div className="mb-3">
            <h2>Your streak: {userStreak}</h2>
          </div>
          <div className="mb-3">
            <p>Sum of Won Coins: {sumOfCoins}</p>
          </div>
          <div className="mb-3 d-flex flex-column align-items-center">
            <label htmlFor="yourBet" className="form-label mb-2">Coins to Bet:</label>
            <div className="input-group mb-2">
              <input
                type="number"
                id="coinsToBet"
                className="form-control bg-black text-white text-center"
                style={{ width: "100px" }}
                placeholder="Place your bets"
                value={amountOfCoinsToBet}
                step="20"
                min="20"
                max={sumOfCoins}
                onKeyDown={(e) => e.preventDefault()}
                onChange={(e) => {
                    setAmountOfCoinsToBet(e.target.value)
                }}
              />
            </div>
            <label htmlFor="coinsToBet" className="form-label mb-2">User Bet:</label>
            <input
              type="text"
              id="userBet"
              className="form-control bg-black text-white text-center"
              style={{ width: "100px" }}
              placeholder="Your bet"
              value={userBet}
              onChange={(e) => checkUserBet(e)}
            />
            <button className="btn btn-danger mt-2" id='place-bet' disabled={placeBetOnce} onClick={handlePlaceBetClick}>Place Bet</button>
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default RoulettePage;